<script setup>
  import { ref } from "vue"

  import ArmSizeExample1 from "@/components/svg/ArmSizeExample1.vue"
  import ArmSizeExample2 from "@/components/svg/ArmSizeExample2.vue"
  import ArmSizeExample3 from "@/components/svg/ArmSizeExample3.vue"
  import { setCurrentViewContext } from "@/utils/GlobalState"

  const highlightedImageNo = ref(0)
  setCurrentViewContext(null, "library")
</script>
<template>
  <article class="library-item">
    <h1 class="title">
      {{ $gettext("How to measure mid-upper arm circumference") }}
    </h1>
    <h2 class="subtitle">
      {{ $gettext("Children 3 months to 5 years") }}
    </h2>
    <section class="columns is-mobile mt-5 mb-5">
      <div class="column is-two-thirds-mobile is-three-quarters-tablet">
        <h2>{{ $gettext("You Need") }}</h2>
        <ul class="mb-4">
          <li>{{ $gettext("A mid-upper arm circumference tape") }}</li>
          <li>{{ $gettext("An assistant (for infants and young children)") }}</li>
        </ul>
        <div class="block">
          <h2>{{ $gettext("Follow these steps") }}</h2>
          <ol>
            <li>{{ $gettext("Sit down, if possible, to work at eye level. Infants and young children can sit in the assistant’s lap.") }} </li>
            <li>{{ $gettext("Remove any clothing that covers the child’s left arm.") }} </li>
            <li>
              {{ $gettext("Find the mid-point of the child’s upper arm by doing the following steps:") }}
              <ol>
                <li>{{ $gettext("Locate the tip of the child’s shoulder with your fingertips.") }} </li>
                <li>{{ $gettext("Bend the child’s elbow so the arm makes a right angle.") }} </li>
                <li>
                  {{ $gettext("Estimate where the middle of the upper arm is (the upper arm is the section between the shoulder tip and the elbow).") }}
                  <span class="muted is-italic">
                    (<a
                      class="interactive-link"
                      @click="highlightedImageNo = 1"
                    >{{ $gettext("See Image %{ number }.", { number: "1" }) }}</a>)
                  </span>
                </li>
                <li>
                  {{ $gettext("Mark this as the mid-point.") }}
                  <span class="muted is-italic">
                    (<a
                      class="interactive-link"
                      @click="highlightedImageNo = 2"
                    >{{ $gettext("See Image %{ number }.", { number: "2" }) }}</a>)
                  </span>
                </li>
              </ol>
            </li>
            <li>{{ $gettext("Straighten the child’s arm.") }} </li>
            <li>{{ $gettext("Wrap the tape around the child’s arm at the mid-point mark you have just made.") }} </li>
            <li>
              {{ $gettext("Make sure that the tape is:") }}
              <ol>
                <li>{{ $gettext("against the skin and not over any clothing") }} </li>
                <li>{{ $gettext("horizontal around the child’s arm") }} </li>
                <li>{{ $gettext("not too tight (the band is too tight if the skin bunches up), and") }} </li>
                <li>{{ $gettext("not too loose (the band is too loose if you can fit a pencil under it).") }} </li>
              </ol>
            </li>
            <li>
              {{ $gettext("Read the measurement and record it to the nearest 0.1 cm (e.g. 14.2 cm).") }}
              <span class="muted is-italic">
                (<a
                  class="interactive-link"
                  @click="highlightedImageNo = 3"
                >{{ $gettext("See Image %{ number }.", { number: "3" }) }}</a>)
              </span>
            </li>
          </ol>
        </div>
        <aside class="card">
          <div class="card-content">
            <h2>{{ $gettext("Tips") }}</h2>
            <ul>
              <li>{{ $gettext("Use a measuring tape that cannot be stretched (non-elastic).") }}</li>
              <li>{{ $gettext("If using the special insertion tapes, insert the end of the band through the thin opening at the other end of the band.") }}</li>
            </ul>
          </div>
        </aside>
      </div>
      <div class="column">
        <figure :class="{'with-overlaid-caption': true, highlighted: highlightedImageNo === 1}">
          <ArmSizeExample1 />
          <figcaption>
            <strong>
              {{ $gettext("Image %{ number }:", { number: 1 }) }}
            </strong>
            {{ $gettext("Find the midpoint") }}
          </figcaption>
        </figure>
        <figure :class="{'with-overlaid-caption': true, highlighted: highlightedImageNo === 2}">
          <ArmSizeExample2 />
          <figcaption>
            <strong>
              {{ $gettext("Image %{ number }:", { number: 2 }) }}
            </strong>
            {{ $gettext("Mark the midpoint") }}
          </figcaption>
        </figure>
        <figure :class="{'with-overlaid-caption': true, highlighted: highlightedImageNo === 3}">
          <ArmSizeExample3 />
          <figcaption>
            <strong>
              {{ $gettext("Image %{ number }:", { number: 3 }) }}
            </strong>
            {{ $gettext("Take the measurement") }}
          </figcaption>
        </figure>
      </div>
    </section>
    <div class="columns">
      <div class="column">
        <div class="message is-spoon-blue">
          <div class="message-header">
            <p style="margin-bottom: 0">
              {{ $gettext("When measuring children, always:") }}
            </p>
          </div>
          <div class="message-body">
            <ul>
              <li>{{ $gettext("Sanitize your hands before and afterwards.") }}</li>
              <li>{{ $gettext("Sanitize equipment after use.") }}</li>
              <li>{{ $gettext("Store equipment in a dry place.") }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>
<style scoped>
  svg.muac-example {
    height: auto;
    padding-bottom: 2em;
    @media screen and (min-width: 625px) {
    padding-bottom: 3em;
    }
  }
  figure.with-overlaid-caption:not(:last-of-type) {
    margin-bottom: 2em;
  }
</style>
